import { IGame } from "../types"
interface IApiEntity {
  name: string
}

interface IApiGame extends IApiEntity {
  genre: string
  min_players: number
  max_players: number
  modes: IApiEntity[]
  systems: IApiEntity[]
}

export function useApiTransform(data: IApiGame[]): IGame[] {
  return data.map(game => {
    return {
      title: game.name,
      modes: game.modes.map(mode => {
        return { title: mode.name }
      }),
      systems: game.systems.map(system => {
        return { title: system.name }
      }),
    }
  })
}
