const shuffle = array => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i)
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

const oneWayRoundRobin = (player: number) => {
  return (player * (player - 1)) / 2
}

const randomIndex = (count: number) =>
  Math.floor(Math.random() * Math.floor(count))

export { shuffle, oneWayRoundRobin, randomIndex }
