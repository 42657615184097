export function useLocalStorage() {
  function issetStorage(key: string): boolean {
    return localStorage.getItem(key) && localStorage.getItem(key) !== ""
      ? true
      : false
  }
  function setStorage(key: string, value: string | any[]): void {
    if (typeof value === "string") {
      localStorage.setItem(key, value)
    } else {
      // TODO: abfrage, dass hier kein falscher wert kommen kann
      localStorage.setItem(key, JSON.stringify(value))
    }
  }

  function getStorage(key: string, type: "string" | "object" = "string") {
    if (type === "string") {
      return localStorage.getItem(key)
    }
    return JSON.parse(localStorage.getItem(key))
  }
  function getStorageKeys() {
    const gamesPool = "tournament_games_pool"
    const games = "tournament_games"
    const player = "tournament_player"
    const name = "tournament_name"
    const score = "tournament_score"
    return { gamesPool, games, player, name, score }
  }

  function clearStorage() {
    const object = getStorageKeys()
    for (const key in object) {
      localStorage.removeItem(object[key])
    }
  }

  return {
    issetStorage,
    getStorage,
    setStorage,
    getStorageKeys,
    clearStorage,
  }
}
