import React, { useState, createContext, useEffect, useContext } from "react"
import { TournamentContext } from "./TournamentContext"
import { useLocalStorage } from "../hooks"
export const PlayerContext = createContext<any>({})

export const PlayerProvider = ({ children }) => {
  const { setHasPlayers } = useContext(TournamentContext)
  const { issetStorage, getStorage, getStorageKeys } = useLocalStorage()
  // TODO zu null ändern
  const [player, setPlayer] = useState([])
  const [playerCount, setPlayerCount] = useState(0)
  const { player: storagePlayer } = getStorageKeys()

  useEffect(() => {
    if (issetStorage(storagePlayer)) {
      setPlayer(getStorage(storagePlayer, "object"))
      setHasPlayers(true)
    }
  }, [])

  useEffect(() => {
    setPlayerCount(player.length)
  }, [player])

  const resetPlayer = () => {
    setPlayer([])
  }

  return (
    <PlayerContext.Provider
      value={{
        player,
        setPlayer,
        playerCount,
        resetPlayer
      }}
    >
      {children}
    </PlayerContext.Provider>
  )
}
