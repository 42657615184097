import React, { useState, createContext, useEffect } from "react"
import { useLocalStorage } from "../hooks"
export const TournamentContext = createContext<any>({})

export const TournamentProvider = ({ children }) => {
  const [isStarted, setIsStarted] = useState(() => false)
  const [name, setName] = useState(null)
  const [hasName, setHasName] = useState(() => false)
  const [hasPlayers, setHasPlayers] = useState(() => false)
  const [hasGames, setHasGames] = useState(() => false)
  const [isRunning, setIsRunning] = useState(() => false)
  const [roundUpFinished, setRoundUpFinished] = useState(()=>false)

  const { issetStorage, getStorage, getStorageKeys } = useLocalStorage()

  const { name: storageName } = getStorageKeys()
  useEffect(() => {
    if (issetStorage(storageName)) {
      setIsStarted(true)
      setName(getStorage(storageName, "string"))
    }
  }, [])

  useEffect(() => {
    setIsRunning(isStarted && hasPlayers && hasGames && roundUpFinished ? true : false)
  }, [isStarted, hasPlayers, hasGames, roundUpFinished])

  useEffect(() => {
    if (name !== null) {
      setHasName(true)
    }
  }, [name])

  const finishTournament = () => {
    setIsStarted(false)
    setName(null)
    setHasName(false)
    setHasPlayers(false)
    setHasGames(false)
    setRoundUpFinished(false)
    setIsRunning(false)
  }

  return (
    <TournamentContext.Provider
      value={{
        isRunning,
        isStarted,
        setIsStarted,
        hasPlayers,
        setHasPlayers,
        hasGames,
        setHasGames,
        name,
        setName,
        hasName,
        finishTournament,
        setRoundUpFinished
      }}
    >
      {children}
    </TournamentContext.Provider>
  )
}
