import React, { useState, createContext, useContext, useEffect } from "react"
import { useInitialPlayerScore } from "../hooks"
import { PlayerContext, GameStageContext, TournamentContext } from "./"
import { oneWayRoundRobin } from "../utils"
export const FixturesContext = createContext<any>({})

export const FixturesProvider = ({ children }) => {
  const { currentGame, currentGameMode, gameIsRunning } = useContext(
    GameStageContext
  )
  const { hasPlayers } = useContext(TournamentContext)
  const [roundsToPlay, setRoundsToPlay] = useState(0)
  const [roundsPlayed, setRoundsPlayed] = useState(0)
  const { playerCount, player } = useContext(PlayerContext)
  const [roundPoints, setRoundPoints] = useState(null)
  const [allRoundsPlayed, setAllRoundsPlayed] = useState(false)
  const { initialScore } = useInitialPlayerScore(player)

  useEffect(() => {
    if (hasPlayers) {
      setRoundPoints(initialScore)
    }
  }, [hasPlayers])

  useEffect(() => {
    if (!gameIsRunning) {
      setRoundsPlayed(0)
      setRoundsToPlay(0)
      setAllRoundsPlayed(false)
      setRoundPoints(initialScore)
    }
  }, [gameIsRunning])

  function setRoundPlayed() {
    setRoundsPlayed(prev => prev + 1)
  }

  useEffect(() => {
    setRoundsToPlay(getRoundsToPlay(currentGameMode, playerCount))
  }, [currentGame, currentGameMode, playerCount])

  useEffect(() => {
    if (
      roundsToPlay === roundsPlayed &&
      roundsPlayed !== 0 &&
      roundsToPlay !== 0
    ) {
      setAllRoundsPlayed(true)
    }
  }, [currentGame, roundsToPlay, roundsPlayed])

  return (
    <FixturesContext.Provider
      value={{
        roundsToPlay,
        setRoundPlayed,
        setRoundPoints,
        roundPoints,
        allRoundsPlayed,
      }}
    >
      {children}
    </FixturesContext.Provider>
  )
}

function getRoundsToPlay(mode: string, playercount: number) {
  switch (mode) {
    case "PvP":
      return oneWayRoundRobin(playercount)
    case "Tournament":
      return 4
    case "2on2":
    case "Party":
      return 1
    default:
      return 1
  }
}
