import { IMode, IGame } from "../types"
export function useGameModes() {
  function filterGamesByMode(
    games: IGame[],
    modes: IMode[],
    playercount: number = 0
  ) {
    
    const possibleModes = checkPossibleModes(modes, playercount)
    return games.filter(game => {
      game.modes = game.modes.filter(mode => {
        if (possibleModes) return possibleModes.includes(mode.title)
      })
      return game.modes.length > 0
    })
  }

  function checkPossibleModes(modes, playercount) {
    const possibleModesByPlayerCount = []
    modes.forEach(mode => {
      if (mode.min_players > playercount) return null
      if (!checkRules(playercount, mode.rules)) return null
      possibleModesByPlayerCount.push(mode.title)
    })
    return possibleModesByPlayerCount
  }

  function checkRules(playercount: Number, rule: string) {
    switch (rule) {
      case "even":
        return isEven(playercount)
      default:
        return true
    }
  }

  function isEven(number) {
    return number % 2 === 0 ? true : false
  }

  function chooseRandomMode(gameModes: IMode[]) {
    if (gameModes.length === 1) return gameModes[0].title
    const randomIndex = Math.floor(Math.random() * Math.floor(gameModes.length))
    return gameModes[randomIndex].title
  }

  return { chooseRandomMode, filterGamesByMode }
}
