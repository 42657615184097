/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"

import {
  TournamentProvider,
  PlayerProvider,
  GamesProvider,
  GameStageProvider,
  FixturesProvider,
} from "./src/contexts"

export const wrapRootElement = ({ element }) => (
  <TournamentProvider>
    <PlayerProvider>
      <GamesProvider>
        <GameStageProvider>
          <FixturesProvider>{element}</FixturesProvider>
        </GameStageProvider>
      </GamesProvider>
    </PlayerProvider>
  </TournamentProvider>
)
