import React, { useState, createContext, useEffect, useContext } from "react"
import { TournamentContext } from "./TournamentContext"
import { useLocalStorage } from "../hooks"

export const GamesContext = createContext<any>({})

export const GamesProvider = ({ children }) => {
  const { setHasGames } = useContext(TournamentContext)
// TODO zu null ändern
  const [games, setGames] = useState([])
  const [gamesCount, setGamesCount] = useState(0)

  const { issetStorage, getStorage, getStorageKeys } = useLocalStorage()

  const {gamesPool:storageGamesPool} = getStorageKeys()

  useEffect(() => {
    if (issetStorage(storageGamesPool)) {
      setHasGames(true)
      setGames(getStorage(storageGamesPool, "object"))
    }
  }, [])
  const resetGames = () => {
  setGames([])
}
  useEffect(() => {
    setGamesCount(games.length)
  }, [games])

  return (
    <GamesContext.Provider
      value={{
        games,
        setGames,
        gamesCount,
        resetGames
      }}
    >
      {children}
    </GamesContext.Provider>
  )
}
