import React, { useState, createContext, useEffect, useContext } from "react"
import { useGameModes } from "../hooks"
import { PlayerContext } from "./PlayerContext"
import { GamesContext } from "./GamesContext"
import { shuffle, randomIndex } from "../utils"
import { useLocalStorage } from "../hooks"
export const GameStageContext = createContext<any>({})

export const GameStageProvider = ({ children }) => {
  const { playerCount } = useContext(PlayerContext)
  const { games, gamesCount, setGames } = useContext(GamesContext)
  const {
    issetStorage,
    setStorage,
    getStorage,
    getStorageKeys,
  } = useLocalStorage()

  const [currentGame, setCurrentGame] = useState(null)
  const [gameNumber, setGameNumber] = useState(1)
  const [currentGameMode, setCurrentGameMode] = useState(null)
  const [gameIsRunning, setGameIsRunning] = useState(false)
  const {
    score: storageScore,
    gamesPool: storageGamesPool,
    games: storageGames,
  } = getStorageKeys()

  const { chooseRandomMode } = useGameModes()

  useEffect(() => {
    if (currentGame) setCurrentGameMode(chooseRandomMode(currentGame.modes))
  }, [currentGame])

  useEffect(() => {
    setGameNumber(getCurrentGameNumber)
  }, [currentGame])

  const chooseRandomGame = () => {
    shuffle(games)
    setCurrentGame(games[randomIndex(gamesCount)])
    setGameIsRunning(true)
  }
  const resetGameStage = () => {
    setCurrentGame(null)
    setCurrentGameMode(null)
  }

  function finishRound(roundPoints: any[]) {
    const { roundGameScore } = evaluateRoundScore(roundPoints)
    const currentScore = {
      title: currentGame.title,
      mode: currentGameMode,
      score: roundGameScore,
    }

    if (issetStorage(storageScore)) {
      const score = getStorage(storageScore, "object")
      setStorage(storageScore, [...score, currentScore])
    } else {
      setStorage(storageScore, [currentScore])
    }
    if (issetStorage(storageGamesPool)) {
      setStorage(
        storageGamesPool,
        getStorage(storageGamesPool, "object").filter(
          game => game.title !== currentGame.title
        )
      )
    }
    setGames(getStorage(storageGamesPool, "object"))
    setGameIsRunning(false)
    console.log("finish round")
  }

  function getCurrentGameNumber() {
    if (issetStorage(storageGamesPool) && issetStorage(storageGames)) {
      const gameCount = getStorage(storageGames, "object").length
      const gamesLeftCount = getStorage(storageGamesPool, "object").length
      console.log({gamesCount}, {gamesLeftCount})
      return gameCount - gamesLeftCount + 1
    }
    return 1
  }

  return (
    <GameStageContext.Provider
      value={{
        currentGame,
        currentGameMode,
        chooseRandomGame,
        gameIsRunning,
        resetGameStage,
        finishRound,
        gameNumber,
      }}
    >
      {children}
    </GameStageContext.Provider>
  )
}

function evaluateRoundScore(roundValues: any[]) {
  // TODO improve
  // importatnt
  if (roundValues === null) return
  const roundGameScore = []
  const sortedValues = roundValues.sort((a, b) => b.score - a.score)
  if (
    sortedValues[0].score === sortedValues[1].score &&
    sortedValues[1].score === sortedValues[2].score
  ) {
    console.log("case 1")
    roundGameScore.push({ name: sortedValues[0].name, score: 1 })
    roundGameScore.push({ name: sortedValues[1].name, score: 1 })
    roundGameScore.push({ name: sortedValues[2].name, score: 1 })
  } else if (
    sortedValues[0].score === sortedValues[1].score &&
    sortedValues[1].score !== sortedValues[2].score
  ) {
    console.log("case 2")
    roundGameScore.push({ name: sortedValues[0].name, score: 2 })
    roundGameScore.push({ name: sortedValues[1].name, score: 2 })
  } else if (
    sortedValues[0].score !== sortedValues[1].score &&
    sortedValues[1].score !== sortedValues[2].score &&
    sortedValues[2].score === 0
  ) {
    console.log("case 3")
    roundGameScore.push({ name: sortedValues[0].name, score: 3 })
    roundGameScore.push({ name: sortedValues[1].name, score: 2 })
  } else if (
    sortedValues.length > 3 &&
    sortedValues[1].score === sortedValues[2].score &&
    sortedValues[2].score === sortedValues[3].score
  ) {
    console.log("case 4")
    roundGameScore.push({ name: sortedValues[0].name, score: 3 })
  } else {
    console.log("case 5 else")
    roundGameScore.push({ name: sortedValues[0].name, score: 3 })
    roundGameScore.push({ name: sortedValues[1].name, score: 2 })
    roundGameScore.push({ name: sortedValues[2].name, score: 1 })
  }

  return { roundGameScore }
}
